import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { toast } from '@/components/ui/use-toast'
import { api } from '@/lib/api'
import { formatFileSize, mhtmlToHtml } from '@/lib/formatting'
import {
  CircleXIcon,
  CopyIcon,
  DownloadIcon,
  LinkIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { EventChannels } from 'backend/src/lib/event-channels'
import { ArchiveHeader } from './components/ArchiveHeader'
import { useAnalytics } from '@/hooks/useAnalytics'
import { Helmet } from 'react-helmet'
import { usePrevious } from '@/hooks/usePrevious'
import { Button } from '@/components/ui/button'

export function ArchivePage() {
  const [params] = useSearchParams()
  const url = params.get('url') || ''

  const [showPayModal, setPayModal] = useState(false)

  const analytics = useAnalytics()

  const {
    data: archive,
    isLoading: loadingArchive,
    refetch: refetchArchive,
  } = api.archives.view.useQuery(
    { url },
    {
      refetchOnWindowFocus: !showPayModal,
      refetchOnReconnect: !showPayModal,
      refetchOnMount: !showPayModal,
    },
  )

  const scrapeMutation = api.archives.scrape.useMutation({
    onSettled: () => {
      refetchArchive()
    },
  })

  const { addEventListener, clearEventListeners } = useSse(url)

  const onPayment = async () => {
    toast({ title: 'Payment Received' })

    analytics.sendEvent('payment_made', { url })

    if (!showPayModal) {
      await refetchArchive()
    }
  }

  const onMinted = async () => {
    toast({
      title: 'Minted',
    })

    if (!showPayModal) {
      await refetchArchive()
    }
  }

  const previousShowPayModal = usePrevious(showPayModal)

  useEffect(() => {
    // Need to clear event listeners when the pay modal is opened/closed
    // so we have the latest value of showPayModal
    if (
      typeof previousShowPayModal !== 'undefined' &&
      showPayModal !== previousShowPayModal
    ) {
      clearEventListeners()
    }

    addEventListener('paymentReceived', onPayment)
    addEventListener('minted', onMinted)
    addEventListener('archiveCompleted', async () => await refetchArchive())
    addEventListener('archiveFailed', async () => await refetchArchive())
  }, [addEventListener])

  useEffect(() => {
    if (previousShowPayModal && !showPayModal) {
      refetchArchive()
    }
  }, [showPayModal])

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'resize' && archive) {
        iframeRef.current?.style.setProperty(
          'height',
          `${event.data.height + 20}px`,
        )
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [archive])

  if (loadingArchive) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (archive && 'status' in archive && archive.status === 'scraping') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <LoaderCircleIcon className="h-8 w-8 animate-spin antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving in Progress</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page will automatically refresh when the archive is ready.
        </p>
      </div>
    )
  }

  if (archive && 'status' in archive && archive.status === 'failed') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <CircleXIcon className="h-8 w-8 antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving Failed</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page failed to archive. Please use the button below to try again.
          If the problem persists, please contact support.
        </p>
        <div className="mt-6">
          <Button onClick={() => scrapeMutation.mutate({ url })}>Retry</Button>
        </div>
      </div>
    )
  }

  if (!archive) {
    return <div className="flex justify-center pt-8 lg:pt-14">Not found :(</div>
  }

  let iframeSrc = !archive.plainText
    ? mhtmlToHtml(archive.textContent || '')
    : undefined

  if (iframeSrc) {
    iframeSrc = iframeSrc.replace(
      '</head>',
      `<script>
        function sendHeight() {
          const height = document.body.scrollHeight;
          window.parent.postMessage({ type: 'resize', height }, '*');
        }

        // Send height when the page loads
        window.addEventListener('load', sendHeight);

        // Optionally, send height when the window is resized
        window.addEventListener('resize', sendHeight);
      </script>
    </head>`,
    )
  }

  return (
    <div className="mx-auto w-full max-w-screen-lg pb-8 pt-8 lg:pb-14 lg:pt-14">
      <Helmet title={`${archive.title ?? archive.url}`} />
      <div className="mb-8">
        <ArchiveHeader
          archive={archive}
          payModal={showPayModal}
          setPayModal={setPayModal}
        />
      </div>

      <a
        href={archive.url}
        target="_blank"
        rel="noreferrer noopener"
        className="mb-1 break-words text-3xl font-bold"
      >
        {archive.title ?? archive?.url}
      </a>
      <div className="mb-8 mt-4 flex flex-col gap-2 text-xs sm:mt-1 sm:flex-row sm:items-center">
        <div className="flex items-center gap-2">
          <Popover>
            <PopoverTrigger className="flex items-center gap-1 text-sm text-indigo-600">
              <LinkIcon className="h-4 w-4" /> Source
            </PopoverTrigger>
            <PopoverContent className="ml-4 grid w-full max-w-[calc(100vw-2rem)] grid-cols-[1fr,auto] gap-x-3 sm:max-w-max">
              <a
                href={archive.url}
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm text-indigo-600 underline-offset-4 hover:underline"
                style={{ wordBreak: 'break-word' }}
              >
                {archive.url}
              </a>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(archive.url ?? '')
                  toast({ title: 'Copied' })
                }}
              >
                <CopyIcon className="h-5 w-5 sm:h-4 sm:w-4" />
              </button>
            </PopoverContent>
          </Popover>
          <div>|</div>
          <a
            href={`/api/download?url=${archive.url}`}
            className="flex items-center gap-1 text-sm text-indigo-600"
            onClick={() => analytics.sendEvent('archive_downloaded', { url })}
          >
            <DownloadIcon className="h-4 w-4" /> Download
          </a>
        </div>
        <div className="hidden sm:block">|</div>
        <div className="text-sm">{formatFileSize(archive.fileSize)}</div>
      </div>
      <div className="text-muted-foreground font-mono text-sm">
        Last updated:{' '}
        {new Date(archive.date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </div>
      <section
        style={{
          wordBreak: 'break-word',
          fontFamily: 'monospace',
        }}
        className="bg-background text-foreground mt-8 flex w-full whitespace-pre-wrap"
      >
        {archive.plainText ? (
          archive.textContent
        ) : (
          <iframe
            ref={iframeRef}
            srcDoc={iframeSrc}
            className="w-full overflow-hidden"
          />
        )}
      </section>
    </div>
  )
}

const getSseUrl = (url: string) => `/api/sse?url=${url}`

function useSse(url: string) {
  const [sse, setSse] = useState<EventSource | null>(null)

  useEffect(() => {
    const sse = new EventSource(getSseUrl(url))
    setSse(sse)

    return () => {
      sse.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addEventListener = <Event extends keyof EventChannels>(
    event: Event,
    cb: (data: EventChannels[Event]) => void,
  ) => {
    sse?.addEventListener(event, ({ data }) => {
      cb(JSON.parse(data))
    })
  }

  const clearEventListeners = () => {
    sse?.close()

    const newSse = new EventSource(getSseUrl(url))
    setSse(newSse)
  }

  return { addEventListener, clearEventListeners }
}
