import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardTitle, CardFooter } from './ui/card'
import { IArchive } from '@/types'
import { Badge } from './ui/badge'
import {
  formatDateLong,
  formatDateWithTime,
  formatFileSize,
} from '@/lib/formatting'
import { cn, getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'

interface Props {
  archive: IArchive
}

export function ArchiveCard(props: Props) {
  const { archive } = props

  const ordinalId = archive.fullTextTx?.ordinalId || archive.hashTx?.ordinalId
  const txid = archive.fullTextTx?.txid || archive.hashTx?.txid
  const fullText = !!archive.fullTextTx
  const blockHeight =
    archive.fullTextTx?.blockHeight || archive.hashTx?.blockHeight

  return (
    <div className={`flex flex-col items-center gap-2 sm:flex-row`}>
      <div className="relative w-full min-w-0">
        <div className="absolute -top-3 left-4 flex items-center gap-2">
          {blockHeight ? (
            <a
              href={getBlockUrl(blockHeight)}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              <Badge>Block {blockHeight}</Badge>
            </a>
          ) : null}
          {ordinalId ? (
            <Badge variant={fullText ? 'theme' : 'secondary'}>
              {fullText ? 'Full Text' : 'Hash Only'}
            </Badge>
          ) : (
            <>
              <Badge variant="secondary">Off-chain</Badge>
            </>
          )}
        </div>
        <Link
          to={AppRoutes.buildArchiveRoute(archive.url)}
          key={archive._id}
          className="block w-full min-w-0 transition duration-300 ease-in-out data-[closed]:-translate-x-full data-[closed]:opacity-0"
        >
          <Card className="w-full min-w-0">
            <CardHeader className={`p-4 pb-2 ${cn({ 'pt-6': !!txid })}`}>
              <CardTitle className="text-lg leading-tight underline-offset-4 group-hover:underline">
                {archive.title || new URL(archive.url).hostname}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-4 pb-2 pt-0 text-sm">
              <p className="truncate">{archive.url}</p>
            </CardContent>
            <CardFooter className="text-muted-foreground flex items-center gap-2 p-4 pt-0 font-mono text-xs">
              <time
                title={formatDateWithTime(archive.date)}
                className="text-muted-foreground"
              >
                {formatDateLong(archive.date)}
              </time>
              <div>|</div>
              <div>{formatFileSize(archive.fileSize)}</div>
            </CardFooter>
          </Card>
        </Link>
      </div>
      <div
        className={`mb-4 w-full max-w-full break-words font-mono text-xs leading-normal sm:mb-0 sm:max-w-16 ${ordinalId || archive.mintingInProgress ? 'text-indigo-600' : 'text-muted-foreground'}`}
      >
        {ordinalId && txid ? (
          <a
            href={getOrdinalExplorerUrl(ordinalId)}
            target="_blank"
            rel="noopener noreferrer"
            className="block underline-offset-4 hover:underline"
          >
            {txid}
          </a>
        ) : (
          <div
            className={`${cn({ 'opacity-50': !archive.mintingInProgress, 'animate-pulse': archive.mintingInProgress })}`}
          >
            {new Array(64).fill(0).join('')}
          </div>
        )}
      </div>
    </div>
  )
}
