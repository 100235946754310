import { api } from '@/lib/api'
import { useState } from 'react'
import { BookIcon, LoaderCircleIcon } from 'lucide-react'
import { useInView } from 'react-intersection-observer'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveStatus, BrowseSort } from '@/types'
import { StatusFilter } from './components/StatusFilter'
import { SiteFilter } from './components/DomainFilter'
import { Sorting } from './components/Sorting'
import { Button } from '@/components/ui/button'

interface Props {
  disableEndlessScroll?: boolean
  pageSize?: number
  headerText?: string
  hideFilters?: boolean
  hideTitle?: boolean
  defaultStatus?: ArchiveStatus
  hideMeta?: boolean
}

const PARAMS = { SITE: 'site', SORT: 'sort', STATUS: 'status' }

export function BrowsePage(props: Props) {
  const {
    disableEndlessScroll,
    pageSize,
    headerText,
    hideFilters,
    hideTitle,
    defaultStatus,
    hideMeta,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const site = searchParams.get(PARAMS.SITE) || ''
  const sort: BrowseSort =
    (searchParams.get(PARAMS.SORT) as BrowseSort) || 'desc'
  const status: ArchiveStatus =
    (searchParams.get(PARAMS.STATUS) as ArchiveStatus) || defaultStatus
  const [innerStatus, setInnerStatus] = useState<ArchiveStatus | undefined>(
    status,
  )

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    api.archives.browse.useInfiniteQuery(
      {
        limit: pageSize,
        status: status ? status : 'all',
        domain: site || undefined,
        sort,
      },
      { getNextPageParam: (lastPage) => lastPage.nextPage },
    )

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage()
      }
    },
  })

  const setSite = (newDomain: string) => {
    setSearchParams((prev) => {
      if (newDomain) {
        prev.set(PARAMS.SITE, newDomain)
      } else {
        prev.delete(PARAMS.SITE)
      }
      return prev
    })
  }

  const setStatus = (newStatus: ArchiveStatus) => {
    setInnerStatus(newStatus)

    setSearchParams((prev) => {
      // Since 'all' is the default value, we don't need to store it in a query param
      if (newStatus && newStatus !== 'all') {
        prev.set(PARAMS.STATUS, newStatus)
      } else {
        prev.delete(PARAMS.STATUS)
      }
      return prev
    })
  }

  const setSort = (newSort: BrowseSort) => {
    setSearchParams((prev) => {
      // Since 'desc' is the default value, we don't need to store it in a query param
      if (newSort && newSort !== 'desc') {
        prev.set(PARAMS.SORT, newSort)
      } else {
        prev.delete(PARAMS.SORT)
      }
      return prev
    })
  }

  const isEmpty =
    !isPending && (!data?.pages.length || !data?.pages[0].archives.length)

  return (
    <div className="mx-auto w-full max-w-screen-md pt-8">
      {!hideMeta && <Helmet title="Browse | Ark" />}
      <div className="flex items-center justify-between gap-2">
        {!hideTitle && (
          <h1 className="mb-1 text-3xl font-bold">{headerText || 'Browse'}</h1>
        )}
        {!hideFilters && (
          <div>
            <Sorting sort={sort} setSort={setSort} />
          </div>
        )}
      </div>
      {!hideFilters && (
        <div className="mt-4 flex flex-col items-center justify-between gap-2 sm:flex-row">
          <div className="grid w-full grid-cols-2 items-center gap-2 sm:grid-cols-[auto,auto,auto,1fr]">
            <Button
              variant={site === 'x.com' ? 'default' : 'outline'}
              onClick={() => setSite(site === 'x.com' ? '' : 'x.com')}
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                className="mr-2 h-4 w-4 stroke-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>X</title>
                <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
              </svg>
              X/Twitter
            </Button>
            <Button
              variant={site === 'gutenberg.org' ? 'default' : 'outline'}
              onClick={() =>
                setSite(site === 'gutenberg.org' ? '' : 'gutenberg.org')
              }
            >
              <BookIcon className="mr-2 h-4 w-4" />
              Project Gutenberg
            </Button>
            <SiteFilter site={site} setSite={setSite} />
            <div className="flex justify-end">
              <div className="w-full sm:w-auto">
                <StatusFilter status={innerStatus} setStatus={setStatus} />
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="mt-10 flex flex-col gap-2 sm:mt-8">
        {isPending && (
          <div className="mt-6 flex justify-center sm:col-span-2">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
        {isEmpty && (
          <div className="mt-6 text-center sm:text-left">
            No archives found.
          </div>
        )}

        {data?.pages.map((group) =>
          group.archives.map((a) => <ArchiveCard archive={a} key={a._id} />),
        )}
        {!disableEndlessScroll && hasNextPage && <div ref={ref} />}
      </section>
      {isFetchingNextPage && (
        <div className="mt-6 flex w-full justify-center">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
    </div>
  )
}
