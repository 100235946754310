import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { BrowseSort } from '@/types'
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react'

interface Props {
  sort: BrowseSort
  setSort: (sort: BrowseSort) => void
}

const SORT_OPTIONS: Record<NonNullable<BrowseSort>, string> = {
  desc: 'Newest',
  asc: 'Oldest',
}

export function Sorting(props: Props) {
  const { setSort } = props

  const sort = props.sort ?? 'desc'

  return (
    <Select
      value={sort}
      onValueChange={(value) => setSort(value as BrowseSort)}
    >
      <SelectTrigger
        className="hover:bg-background hover:text-foreground m-0 h-auto gap-1 border-none p-0 focus:ring-transparent"
        hideIcon
      >
        <SelectValue placeholder="Sort" />
        {sort === 'asc' ? (
          <ArrowUpIcon className="h-4 w-4" />
        ) : (
          <ArrowDownIcon className="h-4 w-4" />
        )}
      </SelectTrigger>
      <SelectContent>
        {Object.entries(SORT_OPTIONS).map(([key, value]) => (
          <SelectItem key={key} value={key}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
