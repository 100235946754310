import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { ArchiveStatus } from '@/types'
import { HardDriveIcon } from 'lucide-react'

interface Props {
  status: ArchiveStatus | undefined
  setStatus: (status: ArchiveStatus) => void
}

export function StatusFilter(props: Props) {
  const { status, setStatus } = props

  return (
    <Select value={status} onValueChange={setStatus}>
      <SelectTrigger className="gap-2">
        <HardDriveIcon className="mr-1.5 h-4 w-4 flex-shrink-0" />
        <div>
          <SelectValue placeholder="Status" />
        </div>
      </SelectTrigger>
      <SelectContent className="whitespace-nowrap">
        <SelectItem value="all">All</SelectItem>
        <SelectItem value="on-chain">On-chain</SelectItem>
        <SelectItem value="off-chain">Off-chain</SelectItem>
      </SelectContent>
    </Select>
  )
}
