import { Env } from '@/lib/env'
import { useEffect } from 'react'

export const PAY_BUTTON_STYLE_SRC =
  'https://pay.canonic.xyz/dist/canonic-pay.min.css?q=12312234211112'
export const PAY_BUTTON_SCRIPT_SRC =
  'https://pay.canonic.xyz/dist/canonic-pay.min.js?q=12312234211112'

interface Props {
  paymail: string
  sessionId: string
  productName: string
  price?: number
  receiptEmail: string
  callbackUrl: string
  title?: string
}

export default function PayPlugin(props: Props) {
  const {
    paymail,
    sessionId,
    productName,
    price,
    receiptEmail,
    callbackUrl,
    title,
  } = props

  useEffect(() => {
    const existingScript = document.querySelector('#pay-script')
    const existingDiv = document.querySelector('#canonicPay')

    if (existingScript) {
      existingScript.remove()
    }

    if (existingDiv) {
      existingDiv.remove()
    }

    loadDiv()

    const q = +Date.now()

    const script = document.createElement('script')
    script.src = `${PAY_BUTTON_SCRIPT_SRC}?q=${q}`
    script.id = 'pay-script'
    script.type = 'module'
    script.async = true

    document.head.appendChild(script)
  }, [props])

  const loadDiv = () => {
    const canonicPay = document.createElement('div')
    canonicPay.id = 'canonicPay'
    if (title) {
      canonicPay.setAttribute('data-heading-text', title)
    }
    const ticker = Env.SIGNET ? 'SIGNET' : 'BTC'
    canonicPay.setAttribute('data-paymail', paymail)
    canonicPay.setAttribute('data-session-id', sessionId)
    canonicPay.setAttribute('data-product', productName)
    canonicPay.setAttribute('data-theme', 'light')
    canonicPay.setAttribute('data-tickers', ticker)
    canonicPay.setAttribute('data-price', price?.toString() || '')
    canonicPay.setAttribute('data-email', receiptEmail)
    canonicPay.setAttribute('data-callback-url', callbackUrl)

    document.querySelector('#slot')?.appendChild(canonicPay)
  }

  return <div id="slot" className="mx-auto flex min-h-[496px]" />
}
