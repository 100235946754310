import { Button, buttonVariants } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import {
  ArrowRightIcon,
  ChevronsDownIcon,
  LoaderCircleIcon,
  PlusIcon,
} from 'lucide-react'
import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BrowsePage } from './Browse/BrowsePage'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { MAX_RAW_CONTENT_SIZE } from 'backend/src/lib/constants'
import { formatFileSize } from '@/lib/formatting'
import { cn } from '@/lib/utils'

export function HomePage() {
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: async (url) => {
      await new Promise((resolve) => setTimeout(resolve, 2000))

      navigate(AppRoutes.buildArchiveRoute(url))
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const [url, setUrl] = useState('')
  const [rawTitle, setRawTitle] = useState('')
  const [rawContent, setRawContent] = useState('')
  const browseRef = useRef<HTMLElement>(null)

  const createRawMutation = api.archives.createRaw.useMutation({
    onSuccess: ({ url }) => {
      navigate(AppRoutes.buildArchiveRoute(url))
      toast({
        title: 'Archive Created',
        description: 'Your archive has been successfully created.',
      })
    },
    onError: (error) => {
      toast({
        title: 'Error Creating Archive',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const scrollToBrowse = () => {
    browseRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const rawContentTooLarge = new Blob([rawContent]).size > MAX_RAW_CONTENT_SIZE
  const [hideScrollButton, setHideScrollButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHideScrollButton(true)
      } else if (window.scrollY < 50) {
        setHideScrollButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="flex flex-col">
      <section className="bg-background text-foreground flex h-[50vh] w-full flex-col items-center pt-36 sm:h-[58vh]">
        <div className="flex w-full max-w-screen-sm flex-1 flex-col items-center justify-center text-center">
          <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
            Archive the Web on Bitcoin
          </h1>
          <p className="text-foreground mt-2 max-w-2xl md:text-xl">
            Easily save and access websites with our secure,
            <br />
            decentralized archiving platform. Powered by Bitcoin.
          </p>
          <div className="mt-6 flex w-full flex-col gap-1.5">
            <form
              className="flex flex-1 items-center justify-between overflow-hidden rounded-md border border-indigo-500"
              onSubmit={(e) => {
                analytics.sendEvent('page_scraped', { url })

                e.preventDefault()

                mutation.mutate({ url })
              }}
            >
              <input
                type="url"
                placeholder="Enter a URL"
                className="flex-1 rounded-md p-2 focus-visible:rounded-r-none"
                onChange={(e) => setUrl(e.target.value)}
                autoFocus
                value={url}
              />
              <Button
                type="submit"
                variant="theme"
                className="rounded-none px-5 text-base disabled:opacity-100"
                disabled={mutation.isPending || !url}
              >
                {mutation.isPending ? 'Archiving...' : 'Archive'}
                {mutation.isPending && (
                  <LoaderCircleIcon className="animate-spin" />
                )}
              </Button>
            </form>
            <Dialog>
              <DialogTrigger asChild>
                <button className="hover:border-foreground mr-auto flex items-center gap-1 border-b border-transparent p-0 text-sm">
                  or create from scratch
                  <PlusIcon className="h-4 w-4" />
                </button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-screen-lg">
                <DialogHeader>
                  <DialogTitle>Create New Archive</DialogTitle>
                </DialogHeader>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()

                    if (rawContentTooLarge) {
                      toast({
                        title: 'Content Too Large',
                        description: `Content size exceeds limit of ${formatFileSize(MAX_RAW_CONTENT_SIZE)}.`,
                        variant: 'destructive',
                      })
                      return
                    }

                    if (!rawTitle || !rawContent) {
                      toast({
                        title: 'Missing Data',
                        description: 'Title and content are required.',
                        variant: 'destructive',
                      })
                      return
                    }

                    createRawMutation.mutate({
                      title: rawTitle,
                      content: rawContent,
                    })
                  }}
                >
                  <div className="grid gap-4 py-4">
                    <Input
                      placeholder="Title"
                      value={rawTitle}
                      onChange={(e) => setRawTitle(e.target.value)}
                    />
                    <Textarea
                      placeholder="Content"
                      value={rawContent}
                      rows={20}
                      onChange={(e) => setRawContent(e.target.value)}
                      className="whitespace-pre-wrap"
                      style={{ fontFamily: 'monospace' }}
                    />
                    <p
                      className={cn(
                        'text-muted-foreground text-sm',
                        rawContentTooLarge && 'text-destructive',
                      )}
                    >
                      {formatFileSize(new Blob([rawContent]).size)} /{' '}
                      {formatFileSize(MAX_RAW_CONTENT_SIZE)}
                    </p>
                  </div>
                  <Button
                    type="submit"
                    className="w-full"
                    disabled={
                      createRawMutation.isPending ||
                      rawContentTooLarge ||
                      !rawTitle ||
                      !rawContent
                    }
                  >
                    {createRawMutation.isPending ? 'Creating...' : 'Create'}
                    {createRawMutation.isPending && (
                      <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
                    )}
                  </Button>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </section>
      <section className="pt-12" ref={browseRef}>
        <BrowsePage
          pageSize={12}
          disableEndlessScroll
          hideFilters
          hideTitle
          defaultStatus="on-chain"
          hideMeta
        />
        <div className="mt-4 flex justify-center">
          <Link
            to={AppRoutes.BROWSE}
            className={`${buttonVariants({ variant: 'ghost' })} flex items-center gap-1.5`}
          >
            View all <ArrowRightIcon className="h-5 w-5" />
          </Link>
        </div>
      </section>
      <div
        className={`pointer-events-none fixed inset-0 z-10 hidden h-[100dvh] min-h-screen flex-col justify-end sm:flex ${cn(
          {
            'opacity-0': hideScrollButton,
          },
        )}`}
      >
        <div
          className={`to-background via-background/70 pointer-events-none h-[15vh] w-full bg-gradient-to-b from-transparent transition-opacity duration-500 sm:h-[25vh]`}
        />
      </div>
      <div
        className={`fixed inset-x-0 bottom-0 z-20 hidden justify-center pb-1.5 transition-opacity duration-500 sm:flex ${cn({ 'pointer-events-none opacity-0': hideScrollButton })}`}
      >
        <button onClick={scrollToBrowse} className={`text-black`}>
          <ChevronsDownIcon className="h-6 w-6 stroke-black" />
        </button>
      </div>
    </div>
  )
}
