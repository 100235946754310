import { useState, useMemo } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getSortedRowModel,
} from '@tanstack/react-table'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Badge } from '@/components/ui/badge'
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisIcon,
  LoaderCircleIcon,
  RefreshCcwIcon,
  SearchIcon,
  TrashIcon,
  XCircleIcon,
} from 'lucide-react'

import { IArchive } from '@/types'
import {
  formatCurrency,
  formatDate,
  formatDateWithTime,
} from '@/lib/formatting'
import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { api } from '@/lib/api'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { Input } from '@/components/ui/input'

const colHelper = createColumnHelper<IArchive>()

interface Props {
  archives: IArchive[]
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageSize: number
  currentPage: number
  pageCount: number
  adminSecret: string
  walletBalance: number | undefined
  search: string
  isLoading: boolean
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  refetchData: () => void
  setPageSize: (size: number) => void
  nextPage: () => void
  previousPage: () => void
}

export function ArchiveTable(props: Props) {
  const {
    archives,
    hasNextPage,
    hasPreviousPage,
    pageSize,
    currentPage,
    pageCount,
    refetchData,
    adminSecret,
    walletBalance,
    setPageSize,
    handleSearchChange,
    search,
    isLoading,
    nextPage,
    previousPage,
  } = props

  const [confirmApprovalModal, setConfirmApprovalModal] = useState<
    'standard' | 'premium' | null
  >(null)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)

  const toast = useToast()

  const approveMutation = api.admin.approve.useMutation({
    onSuccess: async () => {
      toast.toast({ description: 'Archive(s) Approved' })

      refetchData()

      setConfirmApprovalModal(null)

      table.resetRowSelection()
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Approving Archive(s)',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const deleteMutation = api.admin.delete.useMutation({
    onSuccess: async () => {
      toast.toast({ description: 'Archive(s) Deleted' })

      refetchData()

      setConfirmDeleteModal(false)

      table.resetRowSelection()
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Deleting Archive(s)',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const scrapeMutation = api.admin.rescrape.useMutation({
    onMutate: () => {
      toast.toast({
        description: (
          <div className="flex items-center gap-1.5">
            Rescraping... <LoaderCircleIcon className="h-5 w-5 animate-spin" />
          </div>
        ),
      })
    },
    onSuccess: async () => {
      toast.toast({ description: 'Rescraped!' })

      refetchData()

      table.resetRowSelection()
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Rescraping',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const columns = useMemo(
    () => [
      colHelper.display({
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
      }),
      colHelper.accessor('url', {
        header: 'URL',
        cell: ({ getValue }) => {
          const url = getValue()
          return (
            <div className="flex w-full max-w-lg">
              <Link
                to={AppRoutes.buildArchiveRoute(url)}
                className="block truncate underline-offset-4 hover:underline"
                target="_blank"
                title={url}
              >
                {url}
              </Link>
            </div>
          )
        },
      }),
      colHelper.accessor('title', {
        header: 'Title',
        enableSorting: true,
        cell: ({ getValue }) => {
          return (
            <div title={getValue()} className="max-w-[15rem] truncate">
              {getValue()}
            </div>
          )
        },
      }),
      colHelper.display({
        id: 'status',
        cell: ({ row }) => {
          const hashSaved = !!row.original.hashTx
          const fullTextSaved = !!row.original.fullTextTx

          return (
            <div className="flex items-center gap-2">
              {hashSaved && <Badge>Hash</Badge>}
              {fullTextSaved && <Badge>Full Text</Badge>}
            </div>
          )
        },
        header: 'On-Chain',
      }),
      colHelper.accessor('date', {
        header: 'Date',
        cell: ({ getValue }) => {
          return (
            <div
              title={formatDateWithTime(getValue())}
              className="whitespace-nowrap"
            >
              {formatDate(getValue())}
            </div>
          )
        },
      }),
      colHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const [showDelete, setShowDelete] = useState(false)

          const { data } = api.ordinals.estimateFeesUsd.useQuery({
            urls: [row.original.url],
          })

          const standardFees = data?.standardFees
          const premiumFees = data?.premiumFees

          const showStandardPayment =
            standardFees !== null && typeof standardFees !== 'undefined'
          const showPremiumPayment =
            premiumFees !== null && typeof premiumFees !== 'undefined'

          const disableButtons =
            approveMutation.isPending ||
            scrapeMutation.isPending ||
            deleteMutation.isPending ||
            !walletBalance

          const hashSaved = !!row.original.hashTx
          const fullTextSaved = !!row.original.fullTextTx

          return (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <EllipsisIcon className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-6">
                  {!fullTextSaved && (
                    <DropdownMenuItem
                      className="w-full gap-1.5"
                      disabled={disableButtons}
                      onClick={() =>
                        scrapeMutation.mutate({
                          url: row.original.url,
                          secret: adminSecret,
                        })
                      }
                    >
                      <RefreshCcwIcon className="h-4 w-4" />
                      Rescrape
                    </DropdownMenuItem>
                  )}

                  <DropdownMenuSeparator />

                  {!hashSaved && !fullTextSaved && (
                    <DropdownMenuItem
                      className="gap-1.5"
                      disabled={disableButtons || !showStandardPayment}
                      onClick={() =>
                        approveMutation.mutate({
                          type: 'standard',
                          urls: [row.original.url],
                          secret: adminSecret,
                        })
                      }
                    >
                      Approve hash -{' '}
                      {showStandardPayment ? (
                        formatCurrency(standardFees)
                      ) : standardFees === null ? (
                        <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                      ) : (
                        'unavailable'
                      )}
                    </DropdownMenuItem>
                  )}

                  {/* Premium */}

                  {!fullTextSaved && (
                    <DropdownMenuItem
                      className="gap-1.5"
                      disabled={disableButtons || !showPremiumPayment}
                      onClick={() =>
                        approveMutation.mutate({
                          type: 'premium',
                          urls: [row.original.url],
                          secret: adminSecret,
                        })
                      }
                    >
                      Approve full text -{' '}
                      {showPremiumPayment ? (
                        formatCurrency(premiumFees)
                      ) : premiumFees === null ? (
                        <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                      ) : (
                        'unavailable'
                      )}
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuSeparator />

                  <DropdownMenuItem
                    disabled={disableButtons}
                    className="gap-1.5"
                    onClick={() => setShowDelete(true)}
                  >
                    <TrashIcon className="h-4 w-4" />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <AlertDialog open={showDelete} onOpenChange={setShowDelete}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete Archive</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to delete this archive?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        deleteMutation.mutate({
                          urls: [row.original.url],
                          secret: adminSecret,
                        })
                      }}
                    >
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )
        },
      }),
    ],
    [archives, walletBalance],
  )

  const table = useReactTable({
    data: archives,
    columns,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: { pagination: { pageIndex: currentPage - 1, pageSize } },
    initialState: {
      sorting: [{ id: 'date', desc: true }],
    },
  })

  const selectedUrls = table
    .getSelectedRowModel()
    .rows.map((row) => row.original.url)

  const { data: fees } = api.ordinals.estimateFeesUsd.useQuery(
    {
      urls: selectedUrls,
    },
    { enabled: selectedUrls.length > 0 },
  )

  const standardFees = fees?.standardFees

  return (
    <div>
      <div className="mb-4 flex flex-col items-center justify-between gap-2 sm:flex-row sm:gap-8">
        <div className="relative w-full flex-1 sm:w-auto">
          <div className="pointer-events-none absolute left-2 flex h-full items-center">
            <SearchIcon className="stroke-muted-foreground h-4 w-4" />
          </div>
          <Input
            onChange={handleSearchChange}
            value={search}
            placeholder="Search by URL or title"
            className="px-8"
          />
          <div className="absolute inset-y-0 right-2 flex h-full items-center">
            <button
              className={cn({
                'opacity-100': search,
                'pointer-events-none opacity-0': !search,
              })}
              onClick={() =>
                handleSearchChange({
                  target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <XCircleIcon className={`h-4 w-4`} />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <span className="text-sm font-medium">
            {selectedUrls.length} selected
          </span>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="gap-1.5"
                disabled={selectedUrls.length === 0}
              >
                Actions <ChevronDownIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-6">
              <DropdownMenuItem
                onClick={() => setConfirmApprovalModal('standard')}
              >
                Approve hash -{' '}
                {typeof standardFees === 'undefined' ? (
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                ) : (
                  formatCurrency(standardFees ?? 0)
                )}
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => setConfirmDeleteModal(true)}
                className="gap-1.5"
              >
                <TrashIcon className="h-4 w-4" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <AlertDialog
        open={!!confirmApprovalModal}
        onOpenChange={(v) =>
          setConfirmApprovalModal(v ? confirmApprovalModal : null)
        }
      >
        {confirmApprovalModal && (
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Approve {selectedUrls.length} Archive
                {selectedUrls.length === 1 ? '' : 's'}
              </AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to approve the{' '}
                <b className="text-black">
                  {confirmApprovalModal === 'standard' ? 'hash' : 'full text'}
                </b>{' '}
                archival for the selected archive
                {selectedUrls.length === 1 ? '' : 's'}?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  approveMutation.mutate({
                    type: confirmApprovalModal,
                    urls: selectedUrls,
                    secret: adminSecret,
                  })
                }}
              >
                Approve
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        )}
      </AlertDialog>
      <AlertDialog
        open={confirmDeleteModal}
        onOpenChange={setConfirmDeleteModal}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Delete Archive{selectedUrls.length === 1 ? '' : 's'}
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteMutation.mutate({
                  urls: selectedUrls,
                  secret: adminSecret,
                })
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {isLoading && (
        <div className="flex justify-center pt-8 lg:pt-14">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
      {!isLoading && (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() &&
                        header.column.getIsSorted() && (
                          <ArrowUpIcon
                            className={`ml-2 h-4 w-4 ${cn(
                              header.column.getIsSorted() === 'asc',
                              'rotate-180',
                            )}`}
                          />
                        )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={(e) => {
                  const target = e.target as HTMLElement
                  const role = target.getAttribute('role')

                  // Make sure it's not the dropdown menu or link
                  if (
                    row.getCanSelect() &&
                    role !== 'menuitem' &&
                    target.nodeName !== 'A'
                  ) {
                    row.toggleSelected(!row.getIsSelected())
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex items-center gap-2">
          <Label className="whitespace-nowrap">Page size:</Label>
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => setPageSize(Number(value))}
          >
            <SelectTrigger className="w-[4.5rem]">
              <SelectValue placeholder="Default" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="10">10</SelectItem>
              <SelectItem value="25">25</SelectItem>
              <SelectItem value="50">50</SelectItem>
              <SelectItem value="100">100</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={previousPage}
          disabled={!hasPreviousPage}
        >
          <ChevronLeftIcon className="h-4 w-4" /> Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={nextPage}
          disabled={!hasNextPage}
        >
          Next <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}
