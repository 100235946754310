import { buttonVariants } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { api } from '@/lib/api'
import { FilterIcon } from 'lucide-react'

interface Props {
  site: string
  setSite: (site: string) => void
}

export function SiteFilter(props: Props) {
  const { setSite } = props

  const { data: domains } = api.archives.domains.useQuery()

  const _setSite = (site: string) => {
    if (site === 'all') {
      setSite('')
    } else {
      setSite(site)
    }
  }

  const isHighlightedSite = (site: string) => {
    return (
      site.includes('x.com') ||
      site.includes('twitter.com') ||
      site.includes('gutenberg.org')
    )
  }

  const isSelected =
    !isHighlightedSite(props.site) && props.site !== '' && props.site !== 'all'
  const site = isHighlightedSite(props.site) ? '' : props.site
  return (
    <Select value={site} onValueChange={_setSite}>
      <SelectTrigger
        className={`w-full max-w-52 gap-1.5 overflow-hidden hover:text-white focus:ring-transparent sm:w-min ${buttonVariants({ variant: isSelected ? 'default' : 'outline' })}`}
        hideIcon
      >
        <FilterIcon className="h-4 w-4 flex-shrink-0" />
        <div className="overflow-hidden truncate">
          <SelectValue placeholder="More" />
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem key="all" value="all">
            All
          </SelectItem>
          {domains
            ?.filter((domain) => !isHighlightedSite(domain))
            .map((domain) => (
              <SelectItem key={domain} value={domain}>
                {domain}
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
